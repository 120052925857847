import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row g-5 g-xl-8" }
const _hoisted_2 = { class: "col-xl-6" }
const _hoisted_3 = { class: "col-xl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FeedsWidget1 = _resolveComponent("FeedsWidget1")!
  const _component_FeedsWidget2 = _resolveComponent("FeedsWidget2")!
  const _component_FeedsWidget3 = _resolveComponent("FeedsWidget3")!
  const _component_FeedsWidget4 = _resolveComponent("FeedsWidget4")!
  const _component_FeedsWidget5 = _resolveComponent("FeedsWidget5")!
  const _component_FeedsWidget6 = _resolveComponent("FeedsWidget6")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_FeedsWidget1, { "widget-classes": "mb-5 mb-xl-8" }),
      _createVNode(_component_FeedsWidget2, { "widget-classes": "mb-5 mb-xl-8" }),
      _createVNode(_component_FeedsWidget3, { "widget-classes": "mb-5 mb-xl-8" }),
      _createVNode(_component_FeedsWidget4, { "widget-classes": "mb-5 mb-xl-8" })
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode(_component_FeedsWidget5, { "widget-classes": "mb-5 mb-xl-8" }),
      _createVNode(_component_FeedsWidget6, { "widget-classes": "mb-5 mb-xl-8" })
    ])
  ]))
}